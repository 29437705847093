import Jsona from "jsona";
import axios from "axios";
import http from "../../api/http";
import Config from "../../../config";
import _ from "lodash";
import jwt_decode from "jwt-decode";

// todo!! use new notification design
import { showNotification } from "../notification";
import { CreateUser } from "../ewallet/users";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

const defaultParams = {
  include: Config.REACT_APP_PROFILE_INCLUDE,
};

export function getAuthHeader(boolAuthHeaderOnly = false) {
  if (localStorage.getItem("eremitsession")) {
    let authSession = JSON.parse(localStorage.getItem("eremitsession"));
    if (!boolAuthHeaderOnly) {
      let request = {
        headers: {
          Authorization: "Bearer " + authSession.accessToken,
        },
      };

      return request;
    } else {
      let request = "Bearer " + authSession.accessToken;

      return request;
    }
  } else {
    return null;
  }
}

export function getClientSwitchAuthHeader(boolAuthHeaderOnly = false) {
  if (localStorage.getItem("selected_switch")) {
    let authSession = JSON.parse(localStorage.getItem("selected_switch"));
    if (!boolAuthHeaderOnly) {
      let request = {
        headers: {
          Authorization: "Bearer " + authSession.accessToken,
        },
      };

      return request;
    } else {
      let request = "Bearer " + authSession.accessToken;

      return request;
    }
  } else {
    return null;
  }
}

export function postLogin(objCredentials) {
  //first check if there is an existing login for the user
  if (
    localStorage.getItem("eremitsession") &&
    localStorage.getItem("eremit_userinfo")
  ) {
    let userInfo = localStorage.getItem("eremit_userinfo");
    let { firstName, lastName } = JSON.parse(userInfo);

    let strDetail = firstName ? " as " + firstName + " " + lastName : "";

    alert(
      "You are already logged-in as " +
        strDetail +
        ".   This window/tab will refresh to use that login."
    );
  }

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_LOGIN_URI;

  let objRequest = {
    data: {
      type: "token",
      attributes: {
        ...objCredentials,
      },
    },
  };

  const request = axios.post(apiBaseUrl, objRequest);
  const dataFormatter = new Jsona();

  return (dispatch) => {
    return request.then(
      ({ data }) => {
        console.log("Login successful, fetching user..", data);

        let res = dataFormatter.deserialize(data);

        let objJwtObject = jwt_decode(res.accessToken);
        let userData = { ...objJwtObject };

        localStorage.setItem("eremitsession", JSON.stringify(res));
        localStorage.setItem("eremit_userinfo", JSON.stringify(userData));

        dispatch({
          type: "LOGIN",
          user: userData,
          auth: res,
        });

        //dispatch(fetchCurrUser(res))
      },
      (error) => {
        console.log(error);

        //simply check type of error message
        if (error.response) {
          // console.log(error.response.data);
          // console.log(error.response.data.errors)
          // console.log(error.response.status);
          // console.log(error.response.headers);
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.response.data.message,
          });
        } else {
          console.log("Error Request", error);
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: strGeneralError,
          });
        }
      }
    );
  };
}

export function postForgetPassword(objEmail) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_IDENTITY_FORGET_URI;

  let objRequest = {
    ...objEmail,
  };

  const request = axios.post(apiBaseUrl, objRequest);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "FORGET_PASSWORD",
          payload: data,
        });

        dispatch(
          showNotification({
            type: "success",
            title: "Password Reset Request Sent!",
            message:
              "A password reset message was sent to your registered email address.",
          })
        );
      },
      (error) => {
        //simply check type of error message
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: error.response.data.message,
          });
        } else {
          console.log("Error Request", error);
          dispatch({
            type: "AUTH_FAILED",
            errorMsg: strGeneralError,
          });
        }
      }
    );
  };
}

export function fetchCurrUser(objAccess) {
  //store the value first...

  localStorage.setItem("eremitsession", JSON.stringify(objAccess));

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_ACCOUNTS_URI.concat(objAccess.id);

  const request = http.get(apiBaseUrl);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        console.log("current user data", data);

        //store the user info to get reloading correctly
        localStorage.setItem("eremit_userinfo", JSON.stringify(data));

        dispatch({
          type: "LOGIN",
          user: data,
          auth: objAccess,
        });
      },
      (error) => {
        //simply check type of error message
        console.log("Error Request", error);
        dispatch({
          type: "AUTH_FAILED",
          errorMsg: strGeneralError,
        });
      }
    );
  };
}

export function loadAuthUserFromSession() {
  //simply fill auth reducer from storage
  let objSession = JSON.parse(localStorage.getItem("eremitsession"));
  let objUserData = JSON.parse(localStorage.getItem("eremit_userinfo"));

  return (dispatch) => {
    dispatch({
      type: "AUTH_LOAD_SESSION",
      user: objUserData,
      auth: objSession,
    });
  };
}

export function postLogout() {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
  };
}

export function setLoading() {
  return (dispatch) => {
    dispatch({
      type: "AUTH_LOADING",
    });
  };
}

export function dismissAuthError() {
  return (dispatch) => {
    dispatch({
      type: "DISMISS_AUTH_ERROR",
    });
  };
}

export function postLoginViaSocialMedia(socialProfile) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_LIST_BY_EMAIL;

  const { _profile, _provider } = socialProfile;

  let params = {
    email: _profile.email,
  };

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    dispatch({ type: "LOGIN_VIA_SOCIAL", socialProfile: _profile });

    request.then(
      ({ data }) => {
        if (!_.isEmpty(data)) {
          // login successfull
          let loginCred = {
            email: _profile.email,
            socialAccountId: _profile.id,
            provider: _provider,
            isSocialMedia: true,
          };

          dispatch({ type: "SOCIAL_LOGIN_COMPLETE" });
          dispatch(postLogin(loginCred));
        } else {
          //register user
          let objParams = {
            socialAccountId: _profile.id,
            email: _profile.email,
            provider: _provider,
            firstname: _profile.first_name || _profile.firstName,
            lastname: _profile.last_name || _profile.lastName,
            isSocialMedia: true,
          };

          dispatch(CreateUser(objParams, true));
        }
      },
      (error) => {
        dispatch({ type: "ERROR_USERS", errorMsg: strGeneralError });
      }
    );
  };
}

export function activateAccount(activationToken) {
  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL.concat(
    `api/v1/auth/verify/${activationToken}`
  );
  const request = axios.post(apiBaseUrl);

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "ACCOUNT_ACTIVATED" });
      },
      (error) => {
        console.log(error.response.data);
        dispatch({
          type: "AUTH_FAILED",
          errorMsg: error.response.data.message,
        });
      }
    );
  };
}
