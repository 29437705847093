import Config from "../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: true,
  loading_first_level: true,
  loading_image: false,
  meta: Config.defaultMeta,
  firstLevel: [],
  selectedCountry: process.env.REACT_APP_DEFAULT_COUNTRY,
  firstLevelLabel: process.env.REACT_APP_1ST_LEVEL_LABEL,
};

export default function countries(state = initState, action) {
  // console.log("countries", action.type)
  switch (action.type) {
    case "SET_COUNTRY":
      return {
        ...state,
        selectedCountry: action.selectedCountry,
      };

    case "COUNTRIES_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false,
      };

    case "COUNTRIES_SET_REGION_DATA":
      return {
        ...state,
        firstLevel: action.data,
      };

    case "COUNTRIES_FIRST_LEVEL_LOADING":
      return {
        ...state,
        error: false,
        loading_first_level: true,
        loadingText: action.loadingMsg,
        loading_image: false,
      };

    case "CLEAR_ADDRESS_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_first_level: false,
        loading_image: false,
      };

    default:
      return state;
  }
}
