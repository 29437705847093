import Config from '../../../config';

const initState = {
    error: false,
    errorMsg: "",
    firstLoad: true,
    loading: false,
    loading_image: false,
    loaded: false,
    loadingText: "Loading...",
    meta: Config.defaultMeta,
    selectedCustomerId: undefined,
    data: [],
}

export default function beneficiary_list(state = initState, action) {

    switch (action.type) {

        case 'BENEFICIARY_LIST_SET_CUSTOMER_ID':
            return {
                ...state,
                selectedCustomerId: action.customerId
            };

        case 'FETCH_BENEFICIARY_LIST':
            return {
                ...state,
                data: action.data,
                loading: false,
                loaded: true,
                firstLoad: false,
                loading_image: false
            }

        case 'ERROR_BENEFICIARY_LIST':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "BENEFICIARY_LIST_LOADING":

            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "BENEFICIARY_LIST_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_BENEFICIARY_LIST_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}