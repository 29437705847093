import http from "../api/http";
import { showNotification } from "./notification";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function copiedToClipboard(copied) {
  return dispatch => {
    dispatch(
      showNotification({
        type: "info",
        message: copied,
        title: "COPIED TO CLIPBOARD"
      })
    );
  };
}

export function setReduxDropdownActionLength(menuLength) {
  return dispatch => {
    dispatch({
      type: "SET_DROPDOWN_ACTION_LENGTH",
      menuLength: menuLength
    });
  };
}

export function toggleReduxActions(toggle) {
  return dispatch => {
    dispatch({
      type: "UPDATE_REDUX_ACTIONS_MENUS",
      toggle: toggle
    });
  };
}

export function setUserAction(user_action) {
  return dispatch => {
    dispatch({
      type: "USER_ACTION",
      user_action: user_action
    });
  };
}

export function showTransactionDialog(customerId) {
  return dispatch => {
    dispatch({
      type: "SHOW_TRANSACTION_DIALOG",
      transactionId: customerId
    });
  };
}

export function closeTransactionDialog() {
  return dispatch => {
    dispatch({
      type: "HIDE_TRANSACTION_DIALOG"
    });
  };
}

export function showTransactionLoading() {
  return dispatch => {
    dispatch({
      type: "SHOW_TRANSACTION_DIALOG_LOADER"
    });
  };
}

export function getSystemLogs(state, instance) {
  let apiBaseUrl = `${process.env.REACT_APP_IDENTITY_BASE_URL}${"api/v1/logs"}`;
  const request = http.get(apiBaseUrl);

  return dispatch => {
    request.then(
      ({ data }) => {
        dispatch({ type: "GET_SYSTEM_LOGS", logFiles: data });
      },
      error => {
        if (
          String(error) ===
          "TypeError: Cannot read property 'parseError' of undefined"
        ) {
          dispatch({ type: "TENANT_LOADING_STOP" });
        } else {
          dispatch(
            showNotification({ type: "error", message: error.messages })
          );
          dispatch({ type: "ERROR_TENANTS", errorMsg: strGeneralError });
        }
      }
    );
  };
}

export function readSystemLogFile(logfile) {
  let apiBaseUrl = `${
    process.env.REACT_APP_IDENTITY_BASE_URL
  }${"api/v1/logs/"}${logfile}`;
  const request = http.get(apiBaseUrl);

  return dispatch => {
    request.then(
      ({ data }) => {
        dispatch({
          type: "READ_SYSTEM_LOGS",
          logDetails: data,
          selectedLogFile: logfile
        });
      },
      error => {
        if (
          String(error) ===
          "TypeError: Cannot read property 'parseError' of undefined"
        ) {
          dispatch({ type: "TENANT_LOADING_STOP" });
        } else {
          dispatch(
            showNotification({ type: "error", message: error.messages })
          );
          dispatch({ type: "ERROR_TENANTS", errorMsg: strGeneralError });
        }
      }
    );
  };
}
