import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: false,
  loaded: false,
  isLoading: false,
  loading_image: false,
  firstLoad: true,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  switcher: [],
  tenantWidgets: null,
  selectedSwitch: {},
  selectedTenant: {},
  tenantInformation: {},
  tenantCreated: false,
  tenantUpdated: false,
  tenantId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: false
    }
  ]
};

export default function tenants(state = initState, action) {
  switch (action.type) {
    case "FETCH_TENANTS_WIDGETS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        tenantWidgets: {
          ...action.tenantWidgets
        }
      };

    case "UPDATE_TENANT_REDUX_DETAILS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        selectedTenant: {
          ...action.userDetails
        }
      };

    case "CLEAR_SELECTED_TENANTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        tenantId: null,
        selectedTenant: {}
      };

    case "FETCH_TENANTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
        meta: action.meta,
        data: action.payload
      };

    case "FETCH_TENANT_SWITCHER":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        meta: action.meta,
        switcher: action.payload
      };

    case "LOGIN_SWITCHER":
      return {
        ...state,
        selectedSwitch: {
          clientId: action.clientId,
          accessToken: action.accessToken
        }
      };

    case "LOAD_DEFAULT_SWITCH":
      return {
        ...state,
        selectedSwitch: {
          ...action.sessionSwitch,
          defaultTenant: action.defaultTenant
        }
      };

    case "FETCH_A_TENANT":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        tenantId: action.tenantId,
        selectedTenant: { ...action.payload }
      };

    case "TENANT_CREATED":
      return {
        ...state,
        error: false,
        loading: false,
        tenantCreated: true,
        loading_image: false,
        selectedTenant: { ...action.payload }
      };

    case "TENANT_UPDATED":
      return {
        ...state,
        error: false,
        loading: false,
        tenantUpdated: true,
        loading_image: false,
        selectedTenant: { ...action.payload }
      };

    case "TENANT_LOADING":
      return {
        ...state,
        loading: true,
        isLoading: true
      };

    case "TENANT_LOADING_STOP":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        isLoading: false
      };

    case "ERROR_TENANTS":
      return {
        ...state,
        error: true,
        loading: false,
        loading_image: false,
        errorMsg: action.errorMsg,
        selectedTenant: {
          customer: {
            kyc: { ...action.payload }
          }
        }
      };

    case "TENANTS_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_TENANTS_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
