export default {
  items: [
    {
      id: "remittance",
      title: "Client Remittance",
      type: "group",
      icon: "icon-support",
      isAdminNav: false,
      children: [
        {
          id: "dashboard-page",
          title: "Dashboard",
          type: "item",
          url: "/client/dashboard",
          classes: "nav-item",
          icon: "feather icon-sidebar"
        },
        {
          id: "customer-page",
          title: "Customers",
          type: "item",
          url: "/sending/customers",
          icon: "feather icon-user-check"
        },
        {
          id: "transaction-page",
          title: "Transactions",
          type: "item",
          url: "/client/transactions",
          classes: "nav-item",
          icon: "feather icon-list"
        },
        {
          id: "setting-enties",
          title: "Settings",
          type: "collapse",
          icon: "feather icon-sliders",
          children: [
            {
              id: "menu-level-currency",
              title: "Currency",
              type: "item",
              url: "/settings/currency",
              icon: "feather icon-activity"
            },
            {
              id: "menu-level-fees",
              title: "Fees",
              type: "item",
              url: "/settings/fees",
              icon: "feather icon-award",
              isAdminNav: true
            },
            {
              id: "forex-page",
              title: "Forex",
              type: "item",
              url: "/forex",
              icon: "feather icon-bar-chart-2"
            },
            {
              id: "menu-level-purposes",
              title: "Purposes",
              type: "item",
              url: "/settings/purposes"
            },
            {
              id: "menu-level-roles",
              title: "Roles & Permissions",
              type: "item",
              url: "/acccounts/roles"
            }
          ]
        }
      ]
    }
  ]
};
