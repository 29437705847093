
const initState = {
    open                  : false,
    error                 : false,
    errorMsg              : "",
    loading               : false,
    loadingText           : "Processing Request...",
    data                  : [],
    defaultSorted         : [
        {
            id  : "createdAt",
            desc: true
        }
    ]
};


export default function dashboard(state = initState, action) {
    switch (action.type) {

        case "FETCH_DASHBOAR_DATA": 
            return {
                ...state,
                error  : false,
                loading: false,
                meta   : action.meta,
                data   : action.payload,
            };
            
        case "DASHBOARD_LOADING": 
            return {
                ...state,
                error        : false,
                loading      : true
            };

        case "DASHBOARD_LOADING_STOP": 
            return {
                ...state,
                error        : false,
                loading      : false
            };

        case "ERROR_DASHBOARD": 
            return {
                ...state,
                error        : true,
                loading      : false,
                loading_image: false,
                errorMsg     : action.errorMsg.messages,
            };

        default: 
            return state;
    }
}