import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedDocumentType: {
    documentTypeCode: "",
    documentTypeLocation: "",
    documentTypeName: "",
    address: "",
    createdAt: "",
    description: "",
    type: "",
    updatedAt: "",
  },
  documentTypeCreated: false,
  documentTypeAction: null,
  documentTypeId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true,
    },
  ],
  dropdownOpen: new Array(10).fill(false),
};

export default function documentTypees(state = initState, action) {
  switch (action.type) {
    case "FETCH_DOCUMENT_TYPES":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
      };

    case "FETCH_A_DOCUMENT_TYPE":
      return {
        ...state,
        error: false,
        selectedDocumentType: {
          ...action.payload,
        },
        documentTypeId: action.documentTypeId,
        loading: false,
        loading_image: false,
      };

    case "DOCUMENT_TYPE_CREATED":
      return {
        ...state,
        error: false,
        selectedDocumentType: {
          ...action.payload,
        },
        documentTypeCreated: true,
        loading: false,
        loading_image: false,
      };

    case "DOCUMENT_TYPE_UPDATED":
      return {
        ...state,
        error: false,
        updatedreated: true,
        loading: false,
        loading_image: false,
      };

    case "ERROR_DOCUMENT_TYPES":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false,
      };

    case "DOCUMENT_TYPES_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false,
      };

    case "DOCUMENT_TYPE_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_DOCUMENT_TYPE_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false,
      };

    default:
      return state;
  }
}
