import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// admin access
const AdminDashboard = React.lazy(() =>
  import("./Containers/Dashboard/AdminDashboard")
);
const Accounts = React.lazy(() => import("./Containers/Accounts/Index"));
const AccountDetails = React.lazy(() =>
  import("./Containers/Accounts/AccountDetails")
);
const Users = React.lazy(() => import("./Containers/Users/Index"));
const Loyalty = React.lazy(() => import("./Containers/Loyalty/Index"));
const LoyaltyDetails = React.lazy(() =>
  import("./Containers/Loyalty/LoyaltyDetails")
);
const LoyaltyPointsTable = React.lazy(() =>
  import("./Containers/Loyalty/LoyaltyPointsTable")
);

// agent access
const Topups = React.lazy(() => import("./Containers/Topups/Index"));

// common access
const Transactions = React.lazy(() =>
  import("./Containers/Transactions/Index")
);
const UserDetails = React.lazy(() => import("./Containers/Users/userDetails"));

// users access
const Dashboard = React.lazy(() => import("./Containers/Dashboard/Index"));
const Payments = React.lazy(() => import("./Containers/Payments/Index"));
const Transfer = React.lazy(() => import("./Containers/Transfer/Index"));
const RequestMoney = React.lazy(() =>
  import("./Containers/RequestMoney/MyRequest")
);
const IncomingRequest = React.lazy(() =>
  import("./Containers/RequestMoney/IncomingRequest")
);

const Settings = React.lazy(() => import("./Containers/Settings/Index"));
const Kyc = React.lazy(() => import("./Containers/Kyc/Index"));

const Cards = React.lazy(() => import("./Containers/Cards/Index"));
const CardDetails = React.lazy(() => import("./Containers/Cards/CardDetails"));

const Rewards = React.lazy(() => import("./Containers/Rewards/Index"));
const RewardsDetails = React.lazy(() =>
  import("./Containers/Rewards/RewardsDetails")
);
const VouchersTable = React.lazy(() =>
  import("./Containers/Rewards/VouchersTable")
);

// Remittance routes
const RemittanceDashboard = React.lazy(() =>
  import("./Containers/Remittance/Dashboard/Dashboard")
);

const Tenants = React.lazy(() => import("./Containers/Remittance/Tenants"));
const TenantDetails = React.lazy(() =>
  import("./Containers/Remittance/Tenants/TenantDetails")
);

const TenantAccounts = React.lazy(() =>
  import("./Containers/Remittance/Accounts")
);
const TenantAccountDetails = React.lazy(() =>
  import("./Containers/Remittance/Accounts/AccountDetails")
);

const Payouts = React.lazy(() => import("./Containers/Remittance/Payouts"));
const PayoutDetails = React.lazy(() =>
  import("./Containers/Remittance/Payouts/PayoutDetails")
);

//Transactions
const SendMoney = React.lazy(() =>
  import("./Containers/Remittance/Transactions/SendMoney")
);

const ClientTransactions = React.lazy(() =>
  import("./Containers/Remittance/Transactions")
);

// Currency
const Currency = React.lazy(() =>
  import("./Containers/Remittance/Currencies/Currency")
);
const CurrencyDetails = React.lazy(() =>
  import("./Containers/Remittance/Currencies/CurrencyDetails")
);

const Fees = React.lazy(() => import("./Containers/Remittance/Fees/Fees"));
const FeeDetails = React.lazy(() =>
  import("./Containers/Remittance/Fees/FeeDetails")
);

const Forex = React.lazy(() => import("./Containers/Forex/Forex"));
const ForexDetails = React.lazy(() =>
  import("./Containers/Forex/ForexDetails")
);

//Customers
const SendingCustomers = React.lazy(() =>
  import("./Containers/Customers/Customers")
);
const SendingCustomerKycDetails = React.lazy(() =>
  import("./Containers/Customers/ViewCustomerKycDetails")
);

// Purpose
const Purposes = React.lazy(() =>
  import("./Containers/Remittance/Purposes/Purposes")
);
const PurposeDetails = React.lazy(() =>
  import("./Containers/Remittance/Purposes/PurposeDetails")
);

const Logs = React.lazy(() => import("./Containers/Remittance/Logs"));

const routes = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Admin Dashboard",
    component: AdminDashboard
  },

  { path: "/dashboard", exact: true, name: "Dashboard", component: Dashboard },
  { path: "/payments", exact: true, name: "Payments", component: Payments },
  { path: "/transfer", exact: true, name: "Transfer", component: Transfer },
  { path: "/topup", exact: true, name: "Top up", component: Topups },
  {
    path: "/request-money",
    exact: true,
    name: "Request Money",
    component: RequestMoney
  },
  {
    path: "/incoming-request",
    exact: true,
    name: "Incoming Request Money",
    component: IncomingRequest
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: Transactions
  },

  { path: "/accounts", exact: true, name: "Accounts", component: Accounts },
  {
    path: "/accounts/:id",
    exact: true,
    name: "View Fund",
    component: AccountDetails
  },

  {
    path: "/wallet/customers",
    exact: true,
    name: "Customers",
    component: Users
  },
  {
    path: "/wallet/customers/:id",
    exact: true,
    name: "View Customer",
    component: UserDetails
  },
  {
    path: "/wallet/customers/kyc/:id",
    exact: true,
    name: "Kyc / Aml",
    component: Kyc
  },

  { path: "/settings", exact: true, name: "Settings", component: Settings },
  {
    path: "/settings/profile",
    exact: true,
    name: "Profile",
    component: UserDetails
  },

  {
    path: "/settings/cards",
    exact: true,
    name: "Payment Cards",
    component: Cards
  },
  {
    path: "/settings/cards/:id",
    exact: true,
    name: "View Cards",
    component: CardDetails
  },

  {
    path: "/loyalty",
    exact: true,
    name: "Loyalty Program",
    component: Loyalty
  },
  {
    path: "/loyalty/:id",
    exact: true,
    name: "View Loyalty",
    component: LoyaltyDetails
  },
  {
    path: "/settings/rewards",
    exact: true,
    name: "Loyalty Rewards",
    component: LoyaltyPointsTable
  },

  {
    path: "/rewards",
    exact: true,
    name: "Loyalty Program",
    component: Rewards
  },
  {
    path: "/rewards/:id",
    exact: true,
    name: "View Loyalty",
    component: RewardsDetails
  },
  {
    path: "/vouchers",
    exact: true,
    name: "Vouchers",
    component: VouchersTable
  },

  // remittance admin
  {
    path: "/client/dashboard",
    exact: true,
    name: "Dashboard",
    component: RemittanceDashboard
  },
  {
    path: "/tenants",
    exact: true,
    name: "Tenants",
    component: Tenants
  },
  {
    path: "/tenants/:id",
    exact: true,
    name: "View Tenant",
    component: TenantDetails
  },
  {
    path: "/tenant-accounts",
    exact: true,
    name: "Accounts",
    component: TenantAccounts
  },
  {
    path: "/tenant-accounts/:id",
    exact: true,
    name: "View Account",
    component: TenantAccountDetails
  },
  {
    path: "/payouts",
    exact: true,
    name: "Payouts",
    component: Payouts
  },
  {
    path: "/payouts/:id",
    exact: true,
    name: "View Payout",
    component: PayoutDetails
  },
  {
    path: "/logs",
    exact: true,
    name: "Logs",
    component: Logs
  },
  //customers
  // settings - kyc

  {
    path: "/sending/customers",
    permissionId: "customer-page",
    exact: true,
    name: "Customers",
    component: SendingCustomers
  },
  {
    path: "/sending/customers/add-user",
    permissionId: "customer-page",
    exact: true,
    name: "Customer Onboarding",
    component: SendingCustomerKycDetails
  },
  {
    path: "/sending/customers/:id",
    permissionId: "customer-page",
    exact: true,
    name: "Customer KYC Details",
    component: SendingCustomerKycDetails
  },
  //send money
  {
    path: "/client/transactions/sendmoney",
    permissionId: "new-transactions-page",
    exact: true,
    name: "Send Money",
    component: SendMoney
  },
  {
    path: "/client/transactions/sendmoney/:customerId",
    permissionId: "new-transactions-page",
    exact: true,
    name: "Send Money",
    component: SendMoney
  },
  {
    path: "/client/transactions",
    exact: true,
    name: "Transactions",
    component: ClientTransactions
  },

  // setting - currency
  {
    path: "/settings/currency",
    permissionId: "menu-level-currency",
    exact: true,
    name: "Currency",
    component: Currency
  },
  {
    path: "/settings/currency/:id",
    permissionId: "menu-level-currency",
    exact: true,
    name: "View Currency",
    component: CurrencyDetails
  },
  {
    path: "/settings/fees",
    permissionId: "menu-level-fees",
    exact: true,
    name: "Fees",
    component: Fees
  },
  {
    path: "/settings/fees/:id",
    permissionId: "menu-level-fees",
    exact: true,
    name: "View Fee",
    component: FeeDetails
  },
  // setting - forex
  {
    path: "/forex",
    permissionId: "menu-level-forex",
    exact: true,
    name: "Forex",
    component: Forex
  },
  {
    path: "/forex/add-forex",
    permissionId: "menu-level-forex",
    exact: true,
    name: "View Forex",
    component: ForexDetails
  },
  {
    path: "/forex/:baseCurrencyId/:quoteCurrencyId",
    permissionId: "menu-level-forex",
    exact: true,
    name: "View Forex",
    component: ForexDetails
  },
  {
    path: "/settings/purposes",
    permissionId: "menu-level-purposes",
    exact: true,
    name: "Purposes",
    component: Purposes
  },
  {
    path: "/settings/purposes/:id",
    permissionId: "menu-level-purposes",
    exact: true,
    name: "View Purpose",
    component: PurposeDetails
  }
];

export default routes;
