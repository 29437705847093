import http from "../../api/http";
import Config from "../../../config";
import { showNotification } from "../notification";
import { setReduxDropdownActionLength } from '../redux_actions';
import { postLogin } from '../authentication/auth';
import _ from "lodash";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function dismissUserError() {
  return (dispatch) => {
    dispatch({ type: "DISMISS_ERROR" });
  };
}

export function setUserLoading(loadingMsg) {
  return (dispatch) => {
    dispatch({ type: "USER_LOADING", loadingMsg: loadingMsg });
  };
}

export function GetUserList(state, instance, userId) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  // let params = state
  //   ? {
  //       page: {
  //         size: state.pageSize,
  //         number: state.page + 1,
  //       },
  //       sort: reactTableSort(state).pop(),
  //       filter: reactTableFilters(state),
  //     }
  //   : {
  //       ...Config.defaultMeta,
  //     };

  let params = {};

  const request = http.get(apiBaseUrl, {
    params,
  });

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "FETCH_USERS", meta: data.meta, payload: data });
        dispatch(setReduxDropdownActionLength(data && data.length))
      },
      (error) => {
        if (
          String(error) ===
          "TypeError: Cannot read property 'parseError' of undefined"
        ) {
          dispatch({ type: "USER_LOADING_STOP" });
        } else {
          dispatch({
            type: "ERROR_USERS",
            errorMsg: strGeneralError,
          });
        }
      }
    );
  };
}

export function GetUser(userId) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_URI.concat(userId);

  let params = {
    params: {
      include: Config.REACT_APP_CLIENTS_INCLUDE,
    },
  };

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "FETCH_A_USER", payload: data, userId: userId });
      },
      (error) => {
        dispatch({ type: "ERROR_USERS", errorMsg: strGeneralError });
      }
    );
  };
}

export function GetUserByAccountId(accountId) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_BY_ACCOUNT_URI;

  let params = { accountId: accountId };

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "FETCH_A_USER_BY_ACCOUNT", payload: data[0], userId: (data || data[0].id) });
      },
      (error) => {
        dispatch({ type: "ERROR_USERS", errorMsg: strGeneralError });
      }
    );
  };
}

export function GetUserByCustomerNumber(referenceNumber, searchBy) {

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_USER_BY_ACCOUNT_URI;

  let params = { accountNumber: referenceNumber };

  if(searchBy === "searchByPhone"){
    params = { phoneNumber: referenceNumber };
  }

  const request = http.get(apiBaseUrl, { params });

  return (dispatch) => {
    request.then(
      ({ data }) => {
        dispatch({ type: "FETCH_A_USER_BY_ACCOUNT_NUMBER", payload: data[0], userId: (data || data[0].id) });

        if(_.isEmpty(data)){
          dispatch(showNotification({ type: "warning", 
          message: `Customer with account number/Phone number ${referenceNumber} not found!`,
          title: 'Customer Not Found!' }));
        }
      },
      (error) => {
        
        dispatch({ type: "ERROR_USERS", errorMsg: strGeneralError });
        dispatch({ type: "CLEAR_USER_SEARCH" });
        dispatch(showNotification({ type: "error", message: error.messages }));

      }
    );
  };
}

export function CreateUser(objParams, autoLogin) {
  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_CREATE_URI;

  let params = {
      ...objParams,
  }

  const request = http.post(apiBaseUrl, params);

  return (dispatch) => {
    
    dispatch(setUserLoading("Creating user information..."));

    request.then(({
      data
    }) => {

      dispatch({ type: 'USER_CREATED', payload: data })
      dispatch(showNotification({ type: "success", message: "User has successfully been created!" }));

      if(autoLogin){

        let loginCred = {
          email           : objParams.email,
          socialAccountId : objParams.socialAccountId,
          provider        : objParams.provider,
          isSocialMedia   : true
        }

        dispatch(postLogin(loginCred));
        dispatch({ type: "SOCIAL_LOGIN_COMPLETE" });

      }

    },
      (error) => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({
          type: 'ERROR_USERS',
          errorMsg: error
        })
      });
  }
}

export function UpdateUser(objParams) {

  let apiBaseUrl =
  process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  let params = {
    params: {
      ...objParams,
    },
  };

  const request = http.put(apiBaseUrl, params);

  return (dispatch) => {

    dispatch(setUserLoading("Updating user information..."));

    request.then(({
      data
    }) => {
      dispatch(showNotification({ type: "success", message: "Client has successfully been updated!" }));
    },
      (error) => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({
          type: 'ERROR_USERS',
          errorMsg: error
        })
      });
  }
}

export function ReplaceUser(objParams) {}