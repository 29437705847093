const initState = {
  loading: false,
  requiredDocs: [],
  loadingMsg: "Loading..",
  transactionId: null,
  dropdownOpen: new Array(10).fill(false),
  moduleActioin: null,
  moduleId: null,
  user_action: "ADD",
  openDialog: false,
  dialogLoading: false,
  dialogLoadingText: "",
  logs: [],
  logDetails: "Please select log file...",
  selectedLogFile: ""
};

export default function redux_actions(state = initState, action) {
  switch (action.type) {
    case "SET_DROPDOWN_ACTION_LENGTH":
      return {
        ...state,
        dropdownOpen: new Array(action.menuLength).fill(false)
      };

    case "UPDATE_REDUX_ACTIONS_MENUS":
      return {
        ...state,
        dropdownOpen: action.toggle
      };

    case "USER_ACTION":
      return {
        ...state,
        loading: false,
        user_action: action.user_action
      };

    // transaction dialog box
    case "SHOW_TRANSACTION_DIALOG":
      return {
        ...state,
        openDialog: true,
        transactionId: action.transactionId
      };

    case "HIDE_TRANSACTION_DIALOG":
      return {
        ...state,
        transactionId: null,
        openDialog: false,
        dialogLoading: false
      };

    case "SHOW_TRANSACTION_DIALOG_LOADER":
      return {
        ...state,
        transactionId: null,
        dialogLoading: true,
        dialogLoadingText: "Please wait, while we process your request..."
      };

    case "GET_SYSTEM_LOGS":
      return {
        ...state,
        logs: action.logFiles
      };

    case "READ_SYSTEM_LOGS":
      return {
        ...state,
        selectedLogFile: action.logFile,
        logDetails: action.logDetails
      };

    default:
      return state;
  }
}
