export default {
  items: [
    {
      id: "navigation",
      title: "Admin Navigation",
      type: "group",
      icon: "icon-support",
      isAdminNav: true,
      children: [
        {
          id: "tenants-page",
          title: "Tenant Manager",
          type: "item",
          url: "/tenants",
          classes: "nav-item",
          icon: "feather icon-command"
        },
        {
          id: "account-page",
          title: "Accounts",
          type: "item",
          url: "/tenant-accounts",
          classes: "nav-item",
          icon: "feather icon-user-check"
        },
        {
          id: "payouts-page",
          title: "Payout Partners",
          type: "item",
          url: "/payouts",
          classes: "nav-item",
          icon: "feather icon-star"
        },
        {
          id: "logs-page",
          title: "System Logs",
          type: "item",
          url: "/logs",
          classes: "nav-item",
          icon: "feather icon-file-text"
        }
        // {
        //   id: "transasctions-page",
        //   title: "Transactions",
        //   type: "item",
        //   url: "/transactions",
        //   classes: "nav-item",
        //   icon: "feather icon-list",
        // },
      ]
    },
    {
      id: "remittance",
      title: "Client Remittance",
      type: "group",
      icon: "icon-support",
      isAdminNav: false,
      children: [
        {
          id: "dashboard-page",
          title: "Dashboard",
          type: "item",
          url: "/client/dashboard",
          classes: "nav-item",
          icon: "feather icon-sidebar"
        },
        {
          id: "customer-page",
          title: "Customers",
          type: "item",
          url: "/sending/customers",
          icon: "feather icon-user-check"
        },
        {
          id: "transaction-page",
          title: "Transactions",
          type: "item",
          url: "/client/transactions",
          classes: "nav-item",
          icon: "feather icon-list"
        },
        {
          id: "setting-enties",
          title: "Settings",
          type: "collapse",
          active: true,
          icon: "feather icon-sliders",
          children: [
            {
              id: "menu-level-currency",
              title: "Currency",
              type: "item",
              url: "/settings/currency",
              icon: "feather icon-activity"
            },
            {
              id: "menu-level-fees",
              title: "Fees",
              type: "item",
              url: "/settings/fees",
              icon: "feather icon-award"
            },
            {
              id: "forex-page",
              title: "Forex",
              type: "item",
              url: "/forex",
              icon: "feather icon-bar-chart-2"
            },
            {
              id: "menu-level-purposes",
              title: "Purposes",
              type: "item",
              url: "/settings/purposes"
            }
          ]
        }
      ]
    }
  ]
};
