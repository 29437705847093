import Config from '../../../config';
import _ from 'lodash'

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    firstLoad: true,
    loaded: false,
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    isCustomerNew: false,
    selectedUser: {
        name: "",
        description: "",
        updatedAt: "",
        dateOfBirth: '',
        status: false
    },
    userCustomerStatus: null,
    customerStatusList: [
        'draft', 'progress', 'missing', 'approved', 'rejected'
    ],
    customerButtonFlow: {
        'initial': ['draft', 'progress'],
        'draft': ['progress'],
        'progress': ['missing', 'approved', 'rejected'],
        'missing': ['draft', 'progress'],
        'approved': ['approved'],
        'rejected': ['draft', 'progress'],
    },
    tempDocs: {},
    documentDisplay: {},
    userCreated: false,
    userUpdated: false,
    logoutRequired: false,
    userAction: null,
    userId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],

}

export default function customers(state = initState, action) {
    // console.log(action.type, action, state)
    let { tempDocs } = state

    switch (action.type) {
        case 'FETCH_CUSTOMERS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false,
                loaded: true,
                firstLoad: false
            };

        case "FETCH_A_CUSTOMER":
            let boolIsCustomerNew = !action.payload.status ? true : false
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload.kyc,
                    branch: action.payload.branch
                },
                isCustomerNew: boolIsCustomerNew,
                userId: action.userId,
                loading: false,
                loading_image: false
            };

        case "CUSTOMER_STARTED":
            return {
                ...state,
                isCustomerNew: action.isCustomerNew
            }

        case "CUSTOMER_CREATED":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                userCreated: true,
                loading: false,
                loading_image: false
            };


        case "CUSTOMER_UPDATED":
            return {
                ...state,
                error: false,
                userUpdated: true,
                // loading: false,
                loading_image: false,
                //reupdate the selected user here...
                selectedUser: {
                    ...action.payload
                },
                logoutRequired: true
            };

        case "CANCEL_LOGOUT":
            return {
                ...state,
                logoutRequired: false
            };

        case 'ERROR_CUSTOMERS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false,
                selectedUser: {
                    ...action.payload
                }
            };

        case "CUSTOMER_DROP_FILE":
            // console.log("CUSTOMER_DROP_FILE", action.id)
            // console.log('docType', action.docType)

            let newDocs = { ...tempDocs }
            newDocs[action.docType.name] = {
                file: action.file,
                preview: URL.createObjectURL(action.file),
                docType: action.docType
            }
            return {
                ...state,
                tempDocs: newDocs
            }

        case "CUSTOMER_REPLACE_FILE":
            //delete tempDoc
            let newReplacedDocs = { ...tempDocs }
            delete newReplacedDocs[action.docType.name]

            //delete docDisplay if available
            let newDocDisplay = { ...state.documentDisplay }
            delete newDocDisplay[action.docType.id]

            return {
                ...state,
                tempDocs: newReplacedDocs,
                documentDisplay: newDocDisplay,
            }

        case "CUSTOMER_GET_IMAGES":
            // console.log('CUSTOMER_GET_IMAGES', action.imageData, action.payload)
            
            
            //update temporary document listing uploader
            let refDocCollection = action.payload.documentTypes
            let refDocId = action.imageData.documentTypeId
            let docTypeRef = _.find(refDocCollection, { 'id': String(refDocId) });
            
            let newDocReplace = { ...tempDocs }
            // console.log('newDocReplace', action.imageData.documentTypeId, newDocReplace, action.payload.documentTypes)
            // console.log('retrieved docRefType', docTypeRef)
        

            newDocReplace[docTypeRef.name] = {
                file: action.payload.image,
                docType: docTypeRef
            }


             //replace document image
             let newReplaceImage = { ...state.documentDisplay }
             newReplaceImage[action.imageData.documentTypeId] = {
                 image: action.payload.image,
                 type: action.payload.type,
                 name: docTypeRef.name
             }
            return {
                ...state,
                documentDisplay: newReplaceImage,
                tempDocs: newDocReplace
            }

        case "UPDATE_CUSTOMER_STATUS":
            return {
                ...state,
                userCustomerStatus: action.status
            }

        case "CUSTOMERS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "CUSTOMER_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_CUSTOMER_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}