import React from 'react';

import Aux from "../../hoc/_Aux";
import '../../assets/scss/style.scss';
import img404 from '../../assets/images/auth/404.png';

class Error404 extends React.Component {
    render() {
        return (
            <Aux>
               <div className="row align-items-center">
                <div className="col-sm-6">
                    <img src={img404} alt="" className="img-fluid mb-4"/>
                </div>
                <div className="col-sm-6">
                    <h1 className="text-primary display-1 font-weight-bolder">404</h1>
                    <h2 className="font-weight-bolder">Not found</h2>
                    <h5 className="mb-4">Page you’re looking for is not found</h5>
                </div>
            </div>
            </Aux>
        );
    }
}

export default Error404;