import numeral from 'numeral'


export default function formatCurrency(intValue = 0, format = process.env.REACT_APP_NUMERAL_FORMAT) {
    
    numeral.defaultFormat(format)

    let retVal = numeral(intValue).format()
    return retVal
}

