import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class FlexBreadcrumb extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        let {
            overrideMenu,
            pageTitle,
            documentTitle,
            crumbData
        } = this.props

        //don't show
        if (!overrideMenu || !crumbData || !pageTitle) {
            return null
        }
        
        if (documentTitle) {
            document.title = documentTitle
        }
        
        //show if override menu is being called
        return <div className="page-block">
            <div className="page-header-title">
                <h5 className="m-b-10">{pageTitle}</h5>
            </div>
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/"><i className="feather icon-home" /></Link>
                </li>
                {
                    crumbData.map((item, index) => {
                        return <li className="breadcrumb-item" key={index}>
                            {item.path &&
                                <Link to={item.path}>{item.title}</Link>
                            }

                            {(!item.path && item.title) &&
                                <span>{item.title}</span>
                            }

                        </li>
                    })
                }
              
            </ul>
        </div>

    }
}

export default FlexBreadcrumb;
