import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  //add a key on expanded transaction
  expandedTransactions: {},
  transactionsAction: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true,
    },
  ],
};

export default function clientTransactions(state = initState, action) {
  switch (action.type) {
    case "FETCH_TRANSACTIONS":
    case "FETCH_REFUNDS":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
      };

    case "FETCH_A_TRANSACTION":
      let newExpandedTransactions = {
        ...state.expandedTransactions,
      };
      //add the new expanded transaction data retrieved
      newExpandedTransactions[action.transactionId] = {
        ...action.payload,
        loaded: true,
      };
      return {
        ...state,
        expandedTransactions: newExpandedTransactions,
      };

    case "TRANSACTIONS_START_SYNC":
      return {
        ...state,
        data: initState.data,
        meta: initState.meta,
        loaded: false,
      };

    case "ERROR_TRANSACTIONS":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loaded: false,
        loading_image: false,
      };

    case "TRANSACTIONS_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false,
      };

    case "TRANSACTION_ASSIGNED":
    case "TRANSACTION_UPDATED":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
      };

    case "TRANSACTIONS_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_TRANSACTIONS_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false,
      };

    case "SET_INITIAL_LOADED":
      return {
        ...state,
        loaded: false,
      };

    default:
      return state;
  }
}
