import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  firstLoad: true,
  loaded: false,
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedFee: {
    feeCode: "",
    feeLocation: "",
    feeName: "",
    address: "",
    createdAt: "",
    description: "",
    type: "",
    updatedAt: ""
  },
  feeCreated: false,
  feeAction: null,
  feeId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: true
    }
  ],
  dropdownOpen: new Array(10).fill(false)
};

export default function fees(state = initState, action) {
  switch (action.type) {
    case "FETCH_FEES":
      return {
        ...state,
        error: false,
        data: action.data,
        meta: action.meta,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false
      };

    case "FETCH_A_FEE":
      return {
        ...state,
        error: false,
        selectedFee: {
          ...action.payload
        },
        feeId: action.feeId,
        loading: false,
        loading_image: false
      };

    case "FEE_CREATED":
      return {
        ...state,
        error: false,
        selectedFee: {
          ...action.payload
        },
        feeCreated: true,
        loading: false,
        loading_image: false
      };

    case "FEE_UPDATED":
      return {
        ...state,
        error: false,
        updatedreated: true,
        loading: false,
        loading_image: false
      };

    case "ERROR_FEE":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false
      };

    case "FEE_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false
      };

    case "FEE_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_FEE_DATA":
      return initState;

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
