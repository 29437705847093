const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: true,
  loading_image: false,
  loadingText: "Processing Widgets...",
  data: {
    completed: "-",
    failed: "-",
    id: undefined,
    pending: "-",
    processing: "-"
  }
};

export default function widgets(state = initState, action) {
  switch (action.type) {
    case "FETCH_WIDGETS":
      return {
        ...state,
        error: false,
        data: { ...action.data },
        loading: false,
        loading_image: false
      };

    case "DISMISS_ERROR":
      return initState;

    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      };

    default:
      return state;
  }
}
