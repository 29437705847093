import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: false,
  loaded: false,
  isLoading: false,
  loading_image: false,
  firstLoad: true,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedTenantAccount: {},
  accountInformation: {},
  accountCreated: false,
  accountUpdated: false,
  accountId: null,
  defaultSorted: [
    {
      id: "createdAt",
      desc: false,
    },
  ],
};

export default function tenant_accounts(state = initState, action) {
  switch (action.type) {
    case "UPDATE_TENANT_ACCOUNT_REDUX_DETAILS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        selectedTenantAccount: {
          ...action.userDetails,
        },
      };

    case "CLEAR_SELECTED_TENANT_ACCOUNTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        accountId: null,
        selectedTenantAccount: {},
      };

    case "FETCH_TENANT_ACCOUNTS":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        loaded: true,
        firstLoad: false,
        meta: action.meta,
        data: action.payload,
      };

    case "FETCH_A_TENANT_ACCOUNT":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        accountId: action.accountId,
        selectedTenantAccount: { ...action.payload },
      };

    case "TENANT_ACCOUNT_CREATED":
      return {
        ...state,
        error: false,
        loading: false,
        accountCreated: true,
        loading_image: false,
        selectedTenantAccount: { ...action.payload },
      };

    case "TENANT_ACCOUNT_UPDATED":
      return {
        ...state,
        error: false,
        loading: false,
        accountUpdated: true,
        loading_image: false,
        selectedTenantAccount: { ...action.payload },
      };

    case "TENANT_ACCOUNT_LOADING":
      return {
        ...state,
        loading: true,
        isLoading: true,
      };

    case "TENANT_ACCOUNT_LOADING_STOP":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        isLoading: false,
      };

    case "ERROR_TENANT_ACCOUNTS":
      return {
        ...state,
        error: true,
        loading: false,
        loading_image: false,
        errorMsg: action.errorMsg,
        selectedTenantAccount: {
          ...action.payload,
        },
      };

    case "DISMISS_TENANT_ACCOUNT_ERROR":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false,
        errorMsg: action.errorMsg,
      };

    //for general notification
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false,
      };

    case "TENANT_ACCOUNT_LIST_DEFAULT":
    case "DISMISS_ERROR":
    case "CLEAR_TENANT_ACCOUNT_DATA":
      return initState;

    default:
      return state;
  }
}
