import { combineReducers } from "redux";

import theme_reducer from "./theme_reducer";
import notification from "./notification";
import auth from "./authentication/auth";
import users from "./ewallet/users";
import accounts from "./ewallet/accounts";
import loyalty from "./ewallet/loyalty";
import requestmoney from "./ewallet/requestmoney";
import ewallet from "./ewallet/wallet";
import transactions from "./ewallet/transactions";
import dashboard from "./ewallet/dashboard";

import redux_actions from "./redux_actions";

import tenants from "./remittance/tenants";
import tenant_accounts from "./remittance/tenant_accounts";
import payouts from "./remittance/payouts";
import clientTransactions from "./transactions/transactions";
import currency from "./remittance/currencies";
import fees from "./remittance/fees";
import forex from "./remittance/forex";

import customers from "./customers/customers";

import dashboard_widgets from "./remittance/widgets";
import dashboard_statistics from "./remittance/statistics";

import send_money from "./remittance/send_money";
import beneficiary_list from "./remittance/beneficiary_list";
import customer_transactions from "./customers/customer_transactions";
import kyc from "./accounts/kyc";
import document_types from "./settings/document_types";
import countries from "./countries";
import purposes from "./settings/purposes";

export default combineReducers({
  theme_reducer,
  notification,
  auth,
  users,
  accounts,
  loyalty,
  ewallet,
  transactions,
  dashboard,
  requestmoney,
  redux_actions,
  tenants,
  tenant_accounts,
  payouts,
  clientTransactions,
  currency,
  fees,
  forex,
  dashboard_widgets,
  dashboard_statistics,
  send_money,
  beneficiary_list,
  customers,
  customer_transactions,
  kyc,
  document_types,
  countries,
  purposes,
});
