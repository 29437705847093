import Config from "../../../config";

const initState = {
    open                 : false,
    error                : false,
    errorMsg             : "",
    successMsg           : "",
    loading              : false,
    loadingText          : "Processing Request...",
    meta                 : Config.defaultMeta,
    data                 : [],
    selectedWalletAccount: {},
    receiverWalletAccount: {},
    accountId            : null,
    defaultSorted        : [
        {
            id  : "createdAt",
            desc: true
        }
    ],
    requestSuccess: false,
    otp_generated : false,
    otp_sessionId : null,
    otpHasError   : false,
    otpErrorMsg   : null
}

export default function ewallet(state = initState, action) {
    
    switch (action.type) {

        case "OTP_HAS_ERROR": 
        return {
            ...state,
            otpHasError     : true,
            otpErrorMsg     : action.otpErrorMsg || "Invalid OTP"
        };

        case "EWALLET": 
            return {
                ...state,
            };
          
        case "FETCH_A_WALLET_ACCOUNT": 
            return {
                ...state,
                error                : false,
                loading              : false,
                loading_image        : false,
                otp_generated        : false,
                accountId            : action.accountId,
                selectedWalletAccount: { ...action.payload },
            };

        case "FETCH_A_RECEIVER_WALLET_ACCOUNT": 
            return {
                ...state,
                error                : false,
                loading              : false,
                loading_image        : false,
                otp_generated        : false,
                accountId            : action.accountId,
                receiverWalletAccount: { ...action.payload },
            };

        case "TRANSACTION_SENT_SUCCESSFULLY": 
            return {
                ...state,
                error         : false,
                errorMsg      : "",
                loading       : false,
                loading_image : false,
                requestSuccess: true,
                otp_generated : false,
                successMsg    : "Request has successfully been executed!"
            }

        case "OTP_SENT": 
            return {
                ...state,
                error         : false,
                errorMsg      : "",
                loading       : false,
                loading_image : false,
                requestSuccess: false,
                otp_generated : true,
                otp_sessionId : action.payload,
                successMsg    : "OTP has been generated successfully!"
            }

        case "OTP_RESENT": 
            return {
                ...state,
                error         : false,
                errorMsg      : "",
                loading       : false,
                loading_image : false,
                requestSuccess: false,
                otp_generated : true,
                otp_sessionId : action.payload,
                successMsg    : "OTP has been generated successfully!"
            }

        case "OTP_CONFIRMED": 
            return {
                ...state,
                error         : false,
                errorMsg      : "",
                loading       : false,
                loading_image : false,
                requestSuccess: true,
                otp_generated : false,
                successMsg    : "Request has successfully been executed!"
            }


        case "WALLET_ACCOUNT_LOADING": 
            return {
                ...state,
                loading: true,
            };

        case "WALLET_ACCOUNT_LOADING_STOP": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
            };

        case "ERROR_WALLET_ACCOUNT": 
            return {
                ...state,
                error        : true,
                loading      : false,
                loading_image: false,
                otp_generated: false,
                errorMsg     : action.errorMsg,
            };

        case "CLEAR_EWALLET": 
            return {
                ...initState
            };
                
    default: 
        return state;
    }
}